import React from 'react'
import clsx from 'clsx'
import { ReactComponent as LocationIcon } from '../../images/icons/location-icon.svg'
import './style.css'

const Building = (props) => {
  /* Company Status will have 3 posible values:
        0- Under construction
        1- Working
        2- Out of business
    */
  const companyStatus =
    props.status >= 0 && props.status < 3 ? parseInt(props.status) : 0
  /*Company´s name that will be shown as a label*/
  const companyName = props.name
  /**/
  const buildingType = props.type

  const typeClass = 'building-type-' + buildingType
  /*Just in case some extra information is needed*/
  const metaInfo = props.metaInfo

  console.log('companyStatus', companyStatus == 1)

  return (
    <div
      className={clsx(
        'building',
        typeClass,
        companyStatus === 0 && 'building-underconstruction',
        companyStatus === 1 && 'building-working',
        companyStatus === 2 && 'building-outofbusiness'
      )}
    >
      <div className="building-icon"></div>
      {companyStatus === 1 && (
        /* Company Name and loc icon only shown if companyStatus is 1 ::*/
        <React.Fragment>
          <h5>{companyName}</h5>
          <LocationIcon />
        </React.Fragment>
      )}
    </div>
  )
}

export default Building
