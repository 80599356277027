import clsx from 'clsx'
import Stack from 'react-bootstrap/Stack'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import HelpButton from '../../components/HelpButton'

export default function InputNumber({
  id,
  label,
  helpTitle,
  helpContent,
  min,
  max,
  value,
  setValue,
  className,
  labelClassName = '',
  labelBreakpointClassName = '',
  disabled = false,
}) {
  const defaultLabelBreakpointClassName = 'col-xs-4 col-md-7 col-lg-5 col-xl-4'

  const handleInputRangeChange = (e) => {
    if (e.target.value !== '') {
      const v = parseInt(e.target.value)

      if (v < min || v > max) {
        return
      }
    }

    setValue(e.target.value)
  }

  return (
    <Form.Group
      as={Row}
      className={clsx('align-items-center', className)}
      controlId={id}
    >
      <Form.Label
        column
        className={clsx(
          labelBreakpointClassName
            ? labelBreakpointClassName
            : defaultLabelBreakpointClassName,
          labelClassName
        )}
      >
        <span dangerouslySetInnerHTML={{ __html: label }} />
      </Form.Label>
      <Col>
        <Stack direction="horizontal" gap={3}>
          <Form.Control
            type="number"
            min={min}
            max={max}
            value={value}
            onChange={handleInputRangeChange}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
            disabled={disabled}
          />
          {(helpTitle || helpContent) && (
            <HelpButton title={helpTitle} content={helpContent} />
          )}
        </Stack>
      </Col>
    </Form.Group>
  )
}
