import './style.css'

const Skyline = (props) => {
  return (
    /* Just a basic separate structure in case we decide animate with some sort of horizontal parallax FX*/
    <div className="skyline">
      <div className="skyline-cloud-0"></div>
      <div className="skyline-cloud-1"></div>
      <div className="skyline-cloud-2"></div>
    </div>
  )
}

export default Skyline
