import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Skyline from '../../components/Skyline'
import { ReactComponent as TrophyIcon } from '../../images/icons/trophy-icon.svg'
import './style.css'

const Leaderboards = (props) => {
  /*fake data Query TBD */
  const leaderboardState = [
    {
      pos: 1,
      firmName: 'Firm 1',
      firmDesign: '',
      profit: 2342342,
    },
    {
      pos: 2,
      firmName: 'Firm 2',
      firmDesign: '',
      profit: 2342342,
    },
    {
      pos: 3,
      firmName: 'Firm 3',
      firmDesign: '',
      profit: 2342342,
    },
    {
      pos: 4,
      firmName: 'Firm 4',
      firmDesign: '',
      profit: 2342342,
    },
    {
      pos: 5,
      firmName: 'Firm 5',
      firmDesign: '',
      profit: 2342342,
    },
    {
      pos: 6,
      firmName: 'Firm 6',
      firmDesign: '',
      profit: 2342342,
    },
    {
      pos: 7,
      firmName: 'Firm 7',
      firmDesign: '',
      profit: 2342342,
    },
    {
      pos: 8,
      firmName: 'Firm 6',
      firmDesign: '',
      profit: 2342342,
    },
  ]

  return (
    <Container fluid as="main" className="p-0 main">
      <Skyline />
      <div className="h-road"></div>
      <div className="grass">
        <Row className="mb-4">
          <Col
            xs={{ span: 12 }}
            sm={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
          >
            <div className="leaderboard-billboard">
              <h1 className="text-center text-primary fw-bold">
                <TrophyIcon width={96} className="leaderboard-trophy-icon" />
                Leaderboards
              </h1>
              <hr className="leaderboard-header" />
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="12" md="4">
                  Select a leaderboard
                </Form.Label>
                <Col sm="12" md="8">
                  <Form.Select aria-label="Default select example">
                    <option>Which leaderboard would you like to view?</option>
                    <option value="1">leaderboard One</option>
                    <option value="2">leaderboard Two</option>
                    <option value="3">leaderboard Three</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Table responsive className="w-100 table leaderboard-bb-table">
                <thead>
                  <tr>
                    <th>Pos</th>
                    <th>First Name</th>
                    <th>Firm Design</th>
                    <th className="text-end">Profit</th>
                  </tr>
                </thead>
                <tbody>
                  {leaderboardState.map((leader) => (
                    <tr key={leader.pos}>
                      <td>{leader.pos}</td>
                      <td>{leader.firmName}</td>
                      <td>
                        <Button
                          size="sm"
                          variant="outline-primary rounded-pill"
                          data-url={leader.firmDesign}
                        >
                          View{' '}
                          <span className="d-none d-sm-inline-block">Firm</span>
                        </Button>
                      </td>
                      <td className="text-end">{leader.profit}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr className="leaderboard-footer1" />
              <hr className="leaderboard-footer2" />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default Leaderboards
