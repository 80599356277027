import clsx from 'clsx'
import Stack from 'react-bootstrap/Stack'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import HelpButton from '../../components/HelpButton'

export default function InputRange({
  id,
  label,
  helpTitle,
  helpContent,
  minLabel,
  maxLabel,
  value,
  setValue,
  className,
  labelClassName = '',
  labelBreakpointClassName = '',
  disabled = false,
}) {
  const defaultLabelBreakpointClassName = 'col-4 col-sm-2 col-md-3 py-0'
  const handleInputRangeChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <Form.Group as={Row} className={clsx('gx-0', className)} controlId={id}>
      <Stack direction="horizontal" className="align-items-start">
        <Form.Label
          column
          className={clsx(
            labelBreakpointClassName
              ? labelBreakpointClassName
              : defaultLabelBreakpointClassName,
            labelClassName
          )}
        >
          <span dangerouslySetInnerHTML={{ __html: label }} />
        </Form.Label>
        <Stack
          direction="horizontal"
          gap={3}
          className="align-items-start"
          as={Col}
          sm={{ offset: 1 }}
          lg={{ offset: 0 }}
        >
          <div className="flex-fill">
            <Form.Range
              min="0"
              max="10"
              step="1"
              value={value}
              onChange={handleInputRangeChange}
              disabled={disabled}
            />
            <Stack direction="horizontal" className="mt-n2">
              <Form.Label className="input-range--label">{minLabel}</Form.Label>
              <Form.Label className="ms-auto input-range--label">
                {maxLabel}
              </Form.Label>
            </Stack>
          </div>
          {(helpTitle || helpContent) && (
            <HelpButton title={helpTitle} content={helpContent} />
          )}
        </Stack>
      </Stack>
    </Form.Group>
  )
}
