import clsx from 'clsx'
import Stack from 'react-bootstrap/Stack'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { useAppState } from '../../hooks/useAppState'

import HelpButton from '../../components/HelpButton'

export default function InputMultipleChoices({
  id,
  label,
  helpTitle,
  helpContent,
  setValue,
  className,
  labelClassName = '',
  labelBreakpointClassName = '',
  disabled = false,
}) {
  const defaultLabelBreakpointClassName = 'col-sm-3 py-0'
  const {
    state: { industries },
    dispatch,
  } = useAppState()

  const handleInputRangeChange = (e) => {
    // This will send notification update to the notification channel
    setValue(e.target.id)

    // Update the app state
    dispatch({ type: 'set-industry', payload: e.target.id })
  }

  return (
    <Form.Group as={Row} className={clsx('gx-0', className)} controlId={id}>
      <Form.Label
        column
        className={clsx(
          labelBreakpointClassName
            ? labelBreakpointClassName
            : defaultLabelBreakpointClassName,
          labelClassName
        )}
      >
        <span dangerouslySetInnerHTML={{ __html: label }} />
      </Form.Label>
      <div className="w-100 d-block d-sm-none"></div>
      <Stack
        direction="horizontal"
        gap={3}
        className="align-items-start"
        as={Col}
        md={{ offset: 1 }}
        lg={{ offset: 0 }}
      >
        <Row>
          <Col>
            <Form.Check
              id="fs"
              label="Financial Services"
              checked={industries.fs}
              disabled={disabled}
              onChange={handleInputRangeChange}
            />
            <Form.Check
              id="cs"
              label="Consumer Products"
              checked={industries.cs}
              disabled={disabled}
              onChange={handleInputRangeChange}
            />
            <Form.Check
              id="hc"
              label="Healthcare"
              checked={industries.hc}
              disabled={disabled}
              onChange={handleInputRangeChange}
            />
            <Form.Check
              id="in"
              label="Industrials"
              checked={industries.in}
              disabled={disabled}
              onChange={handleInputRangeChange}
            />
          </Col>
          <Col>
            <Form.Check
              id="np"
              label="Non-profit"
              checked={industries.np}
              disabled={disabled}
              onChange={handleInputRangeChange}
            />
            <Form.Check
              id="ls"
              label="Life Sciences"
              checked={industries.ls}
              disabled={disabled}
              onChange={handleInputRangeChange}
            />
            <Form.Check
              id="tmt"
              label="Technology, Media &amp; Telecommunications"
              checked={industries.tmt}
              disabled={disabled}
              onChange={handleInputRangeChange}
            />
          </Col>
        </Row>
        <HelpButton title={helpTitle} content={helpContent} />
      </Stack>
    </Form.Group>
  )
}
