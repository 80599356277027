import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import { useSearchParams } from 'react-router-dom'
import './style.css'

import { useAppState } from '../../hooks/useAppState'

import InputSelect from '../../components/InputSelect'
import InputText from '../../components/InputText'
import InputNumber from '../../components/InputNumber'

import tabChevronImg from '../../images/run-tab/tab-chevron.svg'

export default function RunFirm() {
  const {
    state: { year },
    // dispatch,
  } = useAppState()
  const [searchParams, setSearchParams] = useSearchParams()
  const Content = getContent(searchParams.get('tab'))

  useEffect(() => {
    if (searchParams.get('tab') === null) {
      setSearchParams({ tab: 'bid' })
    }
  }, [searchParams, setSearchParams])

  return (
    <Container as="main" className="pb-5 main">
      <Row className="mb-4">
        <Col
          md={{ span: 10, offset: 1 }}
          lg={{ span: 6, offset: 3 }}
          className="text-center"
        >
          <h1 className="text-center text-primary fw-bold d-inline-block">
            <span>Run Your Firm</span>
            <Timeline year={year} />
          </h1>
        </Col>
      </Row>
      {/** Render chevron tabs */}
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <Tabs />
        </Col>
      </Row>
      <Row>
        {/** Render content */}
        <Col>
          <Content />
        </Col>
      </Row>
    </Container>
  )
}

function getContent(search = '') {
  switch (search) {
    case 'execute':
      return Execute
    case 'retrieve':
      return Retrieve
    case 'view':
      return View
    case 'reflect':
      return Reflect
    case 'advance':
      return Advance
    case 'bid':
    default:
      return Bid
  }
}

function Bid() {
  return (
    <Container>
      <Row>
        <Col lg={4}>
          <h2 className="text-primary h5 fw-bold">Time to Bid!</h2>
          <p>
            Your first task is to bid on high-profile work. Select your target
            client from the drop-down list and then enter your bid below.
          </p>
          <p>
            Note that the work that you win does not represent all of your
            business - these are the high-profile engagements only. Your firm
            also runs a number of smaller engagements, and you'll see those
            details soon.
          </p>
          <p>
            When forming a bid, you may wish to revisit the business
            intelligence details on the Explore Gladrey page. The more you know
            about a prospective client, the better!
          </p>
          <p>
            In forming a bid, you may find it useful to refer back to the town
            of Gladrey and the business intelligence of each company.
          </p>
          <Button
            as={Link}
            to="/explore"
            variant="outline-primary rounded-pill"
          >
            Take me to Gladrey!
          </Button>
        </Col>
        <Col>
          <Form as={Row} className="gx-0">
            <Col>
              <h2 className="text-success h3 fw-bold mb-4 mt-5 mt-lg-0">
                Bid Calculator
              </h2>
              <InputSelect
                label="Select your target client"
                options={[
                  'Armstrong Industries',
                  'Berne Inc',
                  'Brubeck Inc',
                  'Coaltrain Consolidated',
                  'Dinah Inc',
                  'EllaComm',
                  'Elvin Industries',
                  'Esperanza Inc.',
                  'Esquivel Inc.',
                  'EST',
                  'Gillespie Co',
                  'HiromiCo',
                  'Iverson Inc',
                  'LightswitchLearn',
                  'Mingus Inc',
                  'MooveMoolah',
                  'Ornette Tech',
                  'Polar Bear Companies',
                  'Primatech',
                  'Sonny Consolidated',
                  'TipTapToe',
                  'Tyner Companies',
                  'Westco',
                  'Zorn Telecom',
                ]}
              />
              <InputText
                id="anticipatedHours"
                className="mb-3"
                label='Anticipated hours to complete engagement <em class="text-gray-300">(auto-populated)</em>'
                labelClassName="col-sm-7 col-md-8 col-xl-9"
                placeholder="--"
                helpTitle="Anticipated hours to complete engagement"
                helpContent={`<p><strong>How was this number computed?</strong></p><p>Your operating parameters determine how efficient your firm is. Less efficient firms will have to bid higher to break even.</p><p>If you want to become more efficient, consider investing more in technology (infrastructure) or moving your philosophy parameter towards <strong><em>aggressive</em></strong>. Other parameters can also affect your efficiency.</p>`}
                disabled
                // value={anticipatedHours}
                // setValue={(value) => setInputValue('set-anticipated-hours', value)}
              />
              <InputText
                id="costPerEngagementHour"
                className="mb-3"
                label='Cost per engagement hour <em class="text-gray-300">(auto-populated)</em>'
                labelClassName="col-sm-7 col-md-8 col-xl-9"
                placeholder="--"
                helpTitle="Cost per engagement hour"
                helpContent={`<p><strong>How was this number computed?</strong></p><p>Your operating parameters determine your cost basis per hour. More expensive firms will have to bid higher to break even.</p><p>If you want to drive costs down, consider reducing your level of expertise and/or compensation. Almost all parameters affect your costs, but these two affect it the most.</p>`}
                disabled
                // value={costPerEngagementHour}
                // setValue={(value) => setInputValue('set-cost-per-engagement-hour', value)}
              />
              <InputText
                id="anticipatedBreakeven"
                className="mb-3"
                labelClassName="col-sm-7 col-md-8 col-xl-9"
                label='Anticipated breakeven point <em class="text-gray-300">(auto-populated)</em>'
                placeholder="--"
                helpTitle="Anticipated breakeven point"
                helpContent={`<p><strong>So...what should we bid?</strong></p><p>Consider bidding around the breakeven point if the client is particularly cost-sensitive and you have invested in areas that are likely to lead to good customer satisfaction. Satisfied customers are more likely to give you more business in future years, and at premium prices.</p><p>This can be a dangerous strategy if your firm is more aggressive, however, since the risks of cost overruns are higher. This can also be a riskier strategy on audit engagements, as they are harder to scope effectively.</p><p>If your firm is lean and aggressive, your breakeven point may well be lower than your competition's. You may be able to get away with bidding higher than breakeven and still undercutting your competitors. Consider bidding 20% above breakeven.</p><p>If you believe you offer a client a pretty good fit for their needs, consider bidding 20 to 25% above your costs. You're bringing value to the client, and you deserve a profit in return.</p><p>Or, if you believe that your firm is the <strong><em>perfect fit</em></strong> for a particular client, and that no other firm matches the client's needs as well as yours, then consider building in a significant premium, such as 80% above your breakeven costs.</p>`}
                disabled
                // value={anticipatedBreakeven}
                // setValue={(value) => setInputValue('set-anticipatedBreakeven', value)}
              />
              <Stack
                direction="horizontal"
                className="mt-5 justify-content-center"
                gap={3}
              >
                <InputNumber
                  id="bid"
                  label="Your Bid:"
                  labelBreakpointClassName="col-4 text-end"
                  min="0"
                  // value={bid}
                  // setValue={(value) => setInputValue('set-bid', value)}
                />
                <Button variant="outline-success rounded-pill">
                  Submit Bid!
                </Button>
              </Stack>
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
function Execute() {
  return (
    <Container>
      <Row>
        <Col lg={4}>
          <h2 className="text-primary h5 fw-bold">Time to Work!</h2>
          <p>
            Great job making bids on high-profile business. Hopefully you won at
            least one of the high-profile engagements your firm bid on. But if
            you didn't, don't panic. There's more work to come! If you did, it's
            time to execute those engagements now.
          </p>
          <p>
            Click <strong>Execute</strong> on all high-profile engagements that
            appear in the table. This will simulate each engagement to
            completion, using your firm's operating parameters.
          </p>
        </Col>
        <Col>
          <h2 className="text-success h3 fw-bold">
            Run High-Profile Engagements
          </h2>
        </Col>
      </Row>
    </Container>
  )
}
function Retrieve() {
  return (
    <Container>
      <Row>
        <Col lg={4}>
          <h2 className="text-primary h5 fw-bold">You've Been Busy!</h2>
          <p>
            It's not just the high-profile engagements that make up the
            financials of your firm. There's also your portfolio of smaller
            engagements! Your firm has been busy, and those results show here.
          </p>
          <p>How profitable were your smaller engagements this year?</p>
        </Col>
        <Col>
          <h2 className="text-success h3 fw-bold">Other Results</h2>
        </Col>
      </Row>
    </Container>
  )
}
function View() {
  return (
    <Container>
      <Row>
        <Col lg={4}>
          <h2 className="text-primary h5 fw-bold">Your Profits</h2>
          <p>
            It's now time to examine all results for this year. Put on your CFO
            hat and consider:
          </p>
          <p>How do things look?</p>
        </Col>
        <Col>
          <h2 className="text-success h3 fw-bold">Financials</h2>
        </Col>
      </Row>
    </Container>
  )
}
function Reflect() {
  return (
    <Container>
      <Row>
        <Col lg={4}>
          <h2 className="text-primary h5 fw-bold">Take a Moment...</h2>
          <p>
            It's been a busy year for your firm, and is a great time to reflect!
          </p>
          <p>
            Think about your firm's financials, and the results you got from
            your high-profile engagements.
          </p>
          <p>
            Consider your firm's financials, and the results of your
            high-profile engagements. Would you like to make changes to your
            firm's design? If so, you can tweak your operating parameters ever
            so slightly - go and do that now.
          </p>
          <p>
            If your financials aren't looking too hot, you may wish to merge
            with another firm. A merge is entirely optional, and both teams will
            have to agree to the merge. Once agreed, direct your merge request
            to the simulation's Host.
          </p>
        </Col>
        <Col>
          <h2 className="text-success h3 fw-bold">Reflect</h2>
        </Col>
      </Row>
    </Container>
  )
}
function Advance() {
  return (
    <Container>
      <Row>
        <Col lg={4}>
          <h2 className="text-primary h5 fw-bold">Take a Moment...</h2>
          <p>
            It's been a busy year for your firm, and is a great time to reflect!
          </p>
          <p>
            Consider your firm's financials, and the results of your
            high-profile engagements. Would you like to make changes to your
            firm's design? If so, you can tweak your operating parameters ever
            so slightly - go and do that now.
          </p>
          <p>
            If your financials aren't looking too hot, you may wish to merge
            with another firm. A merge is entirely optional, and both teams will
            have to agree to the merge. Once agreed, direct your merge request
            to the simulation's Host.
          </p>
          <div className="text-center mt-5 mb-5">
            <Button variant="outline-primary rounded-pill">
              Tweak Firm Design
            </Button>
          </div>
          <p>When you're ready, click "Advance" to move into the next year.</p>
        </Col>
        <Col>
          <h2 className="text-success h3 fw-bold">Advance to Year 2</h2>
        </Col>
      </Row>
    </Container>
  )
}

function Timeline({ year }) {
  return (
    <div className="timeline">
      <div className={clsx('timeline-dot', year === 1 && 'active')}>
        <span className="timeline-label">Year 1</span>
      </div>
      <div className="timeline-connector"></div>
      <div className={clsx('timeline-dot', year === 2 && 'active')}>
        <span className="timeline-label">Year 2</span>
      </div>
      <div className="timeline-connector"></div>
      <div className={clsx('timeline-dot', year === 3 && 'active')}>
        <span className="timeline-label">Year 3</span>
      </div>
      <div className="timeline-connector"></div>
      <div className={clsx('timeline-dot', year === 4 && 'active')}>
        <span className="timeline-label">Year 4</span>
      </div>
      <div className="timeline-connector"></div>
      <div className={clsx('timeline-dot', year === 5 && 'active')}>
        <span className="timeline-label">Year 5</span>
      </div>
    </div>
  )
}

function Tab({ label, active }) {
  const [_, setSearchParams] = useSearchParams()

  return (
    <Button
      id={label.toLowerCase()}
      variant="outline-primary"
      className={clsx(
        'flex-grow-1 shadow-sm run-tab-button',
        active && 'active'
      )}
      onClick={() => setSearchParams({ tab: label.toLowerCase() })}
    >
      {label}
      {/* <img src={tabChevronImg} alt="bid" /> */}
    </Button>
    // <Button
    //   id={label.toLowerCase()}
    //   variant="outline-primary"
    //   className={clsx(
    //     'flex-grow-1 shadow-sm run-tab-button',
    //     active && 'active'
    //   )}
    //   onClick={() => setSearchParams({ tab: label.toLowerCase() })}
    // >
    //   {label}
    // </Button>
  )
}

function Tabs() {
  const [searchParams] = useSearchParams()

  const labels = ['BID', 'EXECUTE', 'RETRIEVE', 'VIEW', 'REFLECT', 'ADVANCE']

  return (
    <Stack direction="horizontal" className="justify-content-center">
      {labels.map((label) => (
        <Tab
          key={label}
          label={label}
          active={label.toLowerCase() === searchParams.get('tab')}
        />
      ))}
    </Stack>
  )
}
