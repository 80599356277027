import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import logo from "../../images/logos/bottom-line-sim-logo.svg"
import { ReactComponent as HelpIcon } from "../../images/icons/help-icon.svg"
import { ReactComponent as ArrowIcon } from "../../images/icons/arrow-icon.svg"

import "./style.css"

export default function Login() {
  return (
    <Container as="main" className="main">
      <Row className="align-items-center justify-content-center login-row">
        <Col xs={10} md={8} lg={6}>
          <h6 className="text-center text-grey-600">A Business Sim from RSM</h6>
          <img
            src={logo}
            className="d-inline-block align-middle img-fluid mt-5 mb-3"
            alt="Bottom Line Simulation"
          />
          <h5 className="text-center text-grey-600">
            Partner Together, Champion the Client
          </h5>
          <Form className="mt-5">
            <Form.Group
              as={Row}
              className="align-items-center justify-content-center"
            >
              <Col xs={10} md={8}>
                <Form.Control
                  type="password"
                  id="accessCode"
                  placeholder="Input your access code..."
                  aria-describedby="accessHelpBlock"
                />
              </Col>
              <Button
                as={Col}
                xs={2}
                md={1}
                variant="light"
                className="login-button"
              >
                <ArrowIcon width="38" height="38" />
              </Button>
            </Form.Group>
            <Col className="text-center mt-4">
              <Button variant="outline" className="px-0 mx-auto">
                <HelpIcon width="24" height="24" fill="currentColor" />
              </Button>
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
