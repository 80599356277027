import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import './style.css'

import { ReactComponent as HelpIcon } from '../../images/icons/help-icon.svg'

export default function HelpButton({ title, content }) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <Button variant="outline" className="p-0" onClick={handleShow}>
        <HelpIcon width="24" height="24" fill="currentColor" />
      </Button>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        contentClassName="rounded-0 shadow-sm px-2 py-2"
        dialogClassName="bls-modal"
      >
        {title && (
          <Modal.Header className="border-0 pb-0">
            <Modal.Title className="h5 text-primary fw-bold">
              {title}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-0">
          <Button variant="outline-success rounded-pill" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
