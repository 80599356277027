import { useRef } from 'react'
import { motion } from 'framer-motion'
import './style.css'

export default function InputTriangle() {
  const constraintRef = useRef(null)

  return (
    <motion.div className="input-triangle-wrapper" ref={constraintRef}>
      <div className="input-triangle-label as">Assurance</div>
      <div className="input-triangle-label ta">Tax</div>
      <div className="input-triangle-label cs">Consulting</div>
      <motion.div
        drag
        dragConstraints={constraintRef}
        dragElastic={0}
        dragMomentum={false}
        dragTransition={{
          power: 0,
          min: 0,
          max: 200,
          timeConstant: 250,
          // // Snap calculated target to nearest 50 pixels
          // modifyTarget: (target) => {
          //   console.log('target', target)
          //   return Math.round(target / 10) * 10
          // },
        }}
        style={{
          cursor: 'grab',
        }}
        whileTap={{ cursor: 'grabbing' }}
        className="input-triangle-knob"
      ></motion.div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 283.49 245.51"
      >
        <g style={{ isolation: 'isolate' }}>
          <motion.g id="triangle" ref={constraintRef}>
            <polygon
              points="141.74 245.01 0.87 245.01 71.31 123 141.74 1 212.18 123 282.62 245.01 141.74 245.01"
              style={{
                fill: 'none',
                strokeMiterlimit: 10,
                fillRule: 'evenodd',
              }}
            />
            <motion.g
              id="knob"
              drag
              dragConstraints={constraintRef}
              dragElastic={0}
              dragMomentum={false}
              dragTransition={{
                power: 0,
                min: 0,
                max: 200,
                timeConstant: 250,
                // Snap calculated target to nearest 50 pixels
                modifyTarget: (target) => {
                  console.log('target', target)
                  // return Math.round(target / 50) * 50
                  return Math.ceil(target / 100) * 100
                },
              }}
              style={{
                cursor: 'grab',
              }}
              whileTap={{ cursor: 'grabbing' }}
            >
              <image
                width="27"
                height="27"
                transform="translate(127.09 152.15)"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAACXBIWXMAAAsSAAALEgHS3X78AAACjUlEQVRIS72WbXPaMBCEH4ExBJLGoXnp5P//ts6kaQpNAgQMqB/uzjrLQJNOJ5rRCCz5Vnu3WjnEGHlPCyGEY3PxnUHCqXUOIGjHjQDRjRFOAxfHJhQoAL2s52B730MIRwEPMlOgHtBHNjTQ3tdubae91r7V//tDgB0wB1QAJXAGjHUc6vMewmQLrIEVsNRxo887gC0wBzTQwBPgEqh0nCAbMLANsAB+A3MdF8gGajLApmYZoyFwAUyBG+AWuALOEbCA1GsDvAIz4AeyyZ6GjMA2hNAAFg4oIPUoEQZT4BtwD9wh7Ea6xsB2wBuysSGpniaYCEQTjVejsTpDUnaDAN0jwCNdZ6KwdHvx2HxLLDo2afS1GiMpu0UYTZFd14gIlhqo0LVjnZ9qUBPMSn9vFTAW2XkaIMy+KKClrkYE8IiIoNa1lgFbVwEvSA1nSD3XSKXCoTSaCs9JqVsCP4HvwBMijBL4imy0RIBH+t6E9jEB2mn07ErtfSQ1S4TZk/a1BkMDVwiICawkKbOxutyucouCdHhrhNFaR3Q0MewRMG9rLfPOwcxQTbqQ0muMjdGQxMCnqyV7XDMwD2IMNkgKTaEV4g7Qrlml8/3s3ZoM1DPzXrdAlPRGArvWlya01Xit8+j6V5JlWXoBKGKMMYTgWa2AZ0S6FySfrBA2V3TPWaFAc33vWeM07LyDeLClvmAu3yc5yCWiuj3pCkKBfgEPiEfONI5P5cE0rpCDm1uQ98ZCA9QkRg/IObSDvyJPIwhH/QLYka4NU5dZ0At/d/1HhOGCJLDYcn1oAL1IoJvaj9xnnQu0dc4cYE2qo6V2zv+8qZuHn/UN0kz849cVrkZ5+9TvxpNgvjngTjsF4NsfcqNlMv/knqUAAAAASUVORK5CYII="
                style={{ mixBlendMode: 'multiply', opacity: 0.35 }}
              ></image>
              <circle
                cx="138.59"
                cy="163.65"
                r="6"
                style={{
                  fill: 'none',
                  stroke: 'rgb(255, 255, 255)',
                  strokeMiterlimit: 10,
                  strokeWidth: '2.71059px',
                }}
              ></circle>
            </motion.g>
          </motion.g>
        </g>
      </svg> */}
      {/* <motion.img
          src={controlKnobIcon}
          alt="control kob"
          width={24}
          height={24}
          drag
          dragConstraints={constraintRef}
          onDrag={(event, info) =>
            console.log(event, info.point.x, info.point.y)
          }
        /> */}
      {/* <motion.div className="input-triangle-control-knob">
      </motion.div> */}
    </motion.div>
  )
}

// const LOBTriangle = forwardRef((props, ref) => (<LOBTriangleImage ref={ref}/>))
// const
