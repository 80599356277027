// import { useEffect, useState, useCallback } from 'react'
// import { v4 as uuidv4 } from 'uuid'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
// import Amplify, { API, graphqlOperation } from 'aws-amplify'
// import { onStatusUpdate } from '../../graphql/subscriptions'
// import { publishStatusUpdate } from '../../graphql/mutations'

import InputText from '../../components/InputText'
import InputNumber from '../../components/InputNumber'
import InputRange from '../../components/InputRange'
import InputMultipleChoices from '../../components/InputMultipleChoices'
import InputTriangle from '../../components/InputTriangle'

import { useAppState } from '../../hooks/useAppState'

// import aws_exports from '../../aws-exports'

// Amplify.configure(aws_exports)

// const UPDATE_TYPE = 'designFirm'

export default function DesignFirm() {
  const {
    state: {
      firmName,
      firmTagline,
      firmValues,
      numberOfPartners,
      expertise,
      // lob,
      infrastructure,
      employeeDev,
      compensation,
      staffing,
      practiceDev,
      practiceDevBalance,
      philosophy,
      geographicFocus,
    },
    dispatch,
  } = useAppState()
  // const [me, setMe] = useState()

  // useEffect(() => {
  //   setMe(uuidv4())
  // }, [])

  // const handleSyncUpdate = useCallback(
  //   (data) => {
  //     // Data must be an object

  //     if (data.type === 'set-industry') {
  //       dispatch({ type: data.type, payload: data.value })
  //     } else {
  //       dispatch({ type: data.type, payload: data.value })
  //     }
  //   },
  //   [dispatch]
  // )

  // useEffect(() => {
  //   const subscription = API.graphql(
  //     graphqlOperation(onStatusUpdate, { to: 'notification' })
  //   ).subscribe({
  //     next: ({ provider, value }) => {
  //       // console.log({ provider, value })
  //       const update = value.data.onStatusUpdate
  //       if (update.type === UPDATE_TYPE) {
  //         if (update.updater !== me) {
  //           handleSyncUpdate(JSON.parse(update.message))
  //         }
  //       }
  //     },
  //     error: (error) => console.warn(error),
  //   })

  //   return function cleanup() {
  //     // Stop receiving data updates from the subscription
  //     subscription.unsubscribe()
  //   }
  // }, [me, handleSyncUpdate])

  const setInputValue = (type, value) => {
    // Update the app state
    // Industries value is handled inside its own component
    if (type !== 'set-industry') {
      dispatch({ type, payload: value })
    }

    // const statusInput = {
    //   to: 'notification',
    //   message: JSON.stringify({ type, value }),
    //   updater: me,
    //   type: UPDATE_TYPE,
    // }

    // API.graphql(graphqlOperation(publishStatusUpdate, statusInput))
  }

  return (
    <Container as="main" className="pb-5 main">
      <Row className="mb-4">
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
          <h1 className="text-center text-primary fw-bold">Design Your Firm</h1>
          <div className="shadow-plain px-3 py-2 mt-4 mb-5 rounded">
            It's time to design your firm! With your team, work through each
            section below. When you're ready, click{' '}
            <strong>Confirm Design</strong> to lock your selections in.
          </div>
        </Col>
      </Row>
      <Form as={Row} className="gx-0">
        <Col md={5}>
          <h5 className="text-primary fw-bold">Identity</h5>
          <Form>
            <InputText
              id="firmName"
              className="mb-3"
              label="Firm Name:"
              placeholder="Enter firm name here"
              helpTitle="Firm Name"
              helpContent={`Many firms are named after a founding partner with an appealing name (e.g., McGladrey, Deloitte), or they combine the initials of several partners (e.g., KPMG, EY, DRM). Very occasionally, firms have names that have nothing to do with the last names of their partners (e.g., The Mercadien Group).`}
              value={firmName}
              setValue={(value) => setInputValue('set-firm-name', value)}
            />
            <InputText
              id="firmTagline"
              className="mb-3"
              label="Firm Tagline:"
              placeholder="Enter firm tagline here"
              helpTitle="Firm Tagline"
              helpContent={`Your tagline can be thought-provoking, or simple and straightforward, and it can help you to stand out in the bid process. To write it, describe your firm in one sentence or phrase (e.g., “The power of being understood.”).`}
              value={firmTagline}
              setValue={(value) => setInputValue('set-firm-tagline', value)}
            />
            <InputText
              id="firmValues"
              className="mb-3"
              label="Firm Values:"
              placeholder="Enter top 3 values here"
              helpTitle="Firm Value"
              helpContent={`What does your firm value? Innovation? Employee development? Building relationships that last?`}
              value={firmValues}
              setValue={(value) => setInputValue('set-firm-values', value)}
            />
            <InputNumber
              id="numPartners"
              className="mb-3"
              label="Number of Partners:"
              helpTitle="Number of Partners"
              helpContent={`Enter the number of partners running your firm, which is the number of people in your group.`}
              min={1}
              max={30}
              value={numberOfPartners}
              setValue={(value) => setInputValue('set-number-partners', value)}
            />
          </Form>
        </Col>
        <Col md={{ offset: 1 }}>
          <h5 className="text-primary fw-bold">Specialization</h5>
          <Form>
            <InputRange
              label="Expertise:"
              className="mb-2"
              id="expertise"
              helpTitle="Expertise"
              helpContent={`Do you want to keep your options open in terms of clients, or do you want to attract specialized clients who demand high expertise? Similarly, do you prefer to stick with established processes, or do you embrace change and look for ways to innovate? The more general you are, the more you'll compete on price, but the larger the universe of potential customers, and the more reliable the work will be (fewer one-off projects). On the other hand, the more specialized you are, the more equipped you might be to take on gnarly, complex problems that have not been solved before. <em>Drag the marker to set your expertise parameter.</em>`}
              minLabel="Routine"
              maxLabel="Unique"
              value={expertise}
              setValue={(value) => setInputValue('set-expertise', value)}
            />
            <InputMultipleChoices
              className="mb-3"
              label="Industries"
              helpTitle="Industries"
              helpContent={`Gladrey has several industries. If you intend to target one or more of them, make your selections here. The fewer targeted industries you have, the more appealing you may be to potential clients demanding high expertise…but being highly specialized will cut down on your universe of clients. Of course, there may be the opportunity to merge with another firm that has different expertise down the road. <em>Select all that apply.</em>`}
              setValue={(value) => setInputValue('set-industry', value)}
            />
          </Form>
        </Col>
        <Col md={5} className="mt-4">
          <h5 className="text-primary fw-bold">Line of Business</h5>
          <p className="text-center mt-4">
            <em>Drag the point to the desired location:</em>
          </p>
          <InputTriangle />
        </Col>
        <Col md={{ offset: 1 }} className="mt-3">
          <h5 className="text-primary fw-bold mb-4">Operating Parameters</h5>
          <Form>
            <InputRange
              className="mb-2"
              label="Infrastructure"
              id="infrastructure"
              helpTitle="Infrastructure"
              helpContent={`Will your employees have the newest equipment and software, or will they have to make do with ancient laptops running Windows 7? Will your offices be located near the top floor of a ritzy building in a prestigious part of town, or will you inhabit more modest accommodations? Consider: Better tech increases efficiency, and it affects how your clients see you. Can you afford to project the image of confidence and growth that comes from nice offices and high-tech tools? <em>Drag the marker to set your infrastructure parameter.</em>`}
              minLabel="Duct tape"
              maxLabel="Shiny"
              value={infrastructure}
              setValue={(value) => setInputValue('set-infrastructure', value)}
            />
            <InputRange
              className="mb-2"
              label="Employee Dev."
              id="employeeDev"
              helpTitle="Employee Development"
              helpContent={`When it comes to developing your employees, will you focus on keeping them credentialed (e.g., to meet their designation requirements) or will you invest in a variety of development opportunities that support their growth in leadership, advisory and specialized skill areas? In addition to job-specific expertise, for example, do you want your employees to be able to develop deep relationships and demonstrate leadership presence, effective communication and business acumen? What about develop industry and global knowledge? Remember: Developing employees is expensive, but it reduces risk and increases customer satisfaction. <em>Drag the marker to set your development parameter.</em>`}
              minLabel="Credential"
              maxLabel="Differentiate"
              value={employeeDev}
              setValue={(value) => setInputValue('set-employee-dev', value)}
            />
            <InputRange
              className="mb-2"
              label="Compensation"
              id="compensation"
              helpTitle="Compensation"
              helpContent={`How well will you pay employees relative to other firms (including benefits)? Low pay increases turnover, which decreases efficiency and keeps clients from building personal relationships with your staff. <em>Drag the marker to set your compensation parameter.</em>`}
              minLabel="Lagging"
              maxLabel="Leading"
              value={compensation}
              setValue={(value) => setInputValue('set-compensation', value)}
            />
            <InputRange
              className="mb-2"
              label="Staffing"
              id="staffing"
              helpTitle="Staffing"
              helpContent={`Will you run lean, or will you staff for maximum flexibility? High staffing levels increase the hours spent on engagements, but they can lead to higher customer satisfaction. <em>Drag the marker to set your staffing parameter.</em>`}
              minLabel="Lean"
              maxLabel="Long"
              value={staffing}
              setValue={(value) => setInputValue('set-staffing', value)}
            />
            <InputRange
              className="mb-2"
              label="Practice Dev."
              id="practiceDev"
              helpTitle="Practice Development"
              helpContent={`Practice development is the art of building relationships, both with potential and current clients. It can include advertising, hiring business developers, charitable giving, and spending unbilled time with customers. Practice development is expensive… but would you hire a firm you'd never heard of? <em>Drag the marker to set your practice development parameter.</em>`}
              minLabel="Minimize"
              maxLabel="Emphasize"
              value={practiceDev}
              setValue={(value) => setInputValue('set-practice-dev', value)}
            />
            <InputRange
              className="mb-2"
              label="Prac. Dev. Balance"
              id="pracDevBalance"
              helpTitle="PD Balance"
              helpContent={`What will your focus be: finding new clients, or developing relationships with the clients you already have? <em>Drag the marker to set your practice development balance parameter.</em>`}
              minLabel="New Clients"
              maxLabel="Existing"
              value={practiceDevBalance}
              setValue={(value) =>
                setInputValue('set-practice-dev-balance', value)
              }
            />
            <InputRange
              className="mb-2"
              label="Philosophy"
              id="philosophy"
              helpTitle="Philosophy"
              helpContent={`How conservative is your firm? More conservative firms tend to prescribe more procedures, avoid pushing work down to less experienced staff, demand more complete documentation, project estimates more conservatively, and generally take fewer risks. It costs money to be conservative… but then again, conservative firms are less likely to blow their budgets on engagements. <em>Drag the marker to set your philosophy parameter.</em>`}
              minLabel="Aggresive"
              maxLabel="Conservative"
              value={philosophy}
              setValue={(value) => setInputValue('set-philosophy', value)}
            />
            <InputRange
              className="mb-2"
              label="Geographic Focus"
              id="geographicFocus"
              helpTitle="Geographic Focus"
              helpContent={`What will your focus be: clients who operate locally, globally, or somewhere in between? <em>Drag the marker to set your geographic focus parameter.</em>`}
              minLabel="Local"
              maxLabel="Global"
              value={geographicFocus}
              setValue={(value) => setInputValue('set-geographic-focus', value)}
            />
          </Form>
        </Col>
      </Form>
      <Row className="justify-content-center mt-5">
        <Col xs="auto">
          <Button variant="outline-success rounded-pill">Confirm Design</Button>
        </Col>
      </Row>
    </Container>
  )
}
