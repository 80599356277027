import { createContext, useContext, useReducer } from 'react'

const AppStateContext = createContext()

export const initialState = {
  year: 1,
  firmName: '',
  firmTagline: '',
  firmValues: '',
  numberOfPartners: '1',
  expertise: '5',
  industries: {
    fs: false,
    cs: false,
    hc: false,
    in: false,
    np: false,
    ls: false,
    tmt: false,
  },
  lob: {
    asMix: 33.4,
    tsMix: 33.3,
    csMix: 33.3,
  },
  infrastructure: '5',
  employeeDev: '5',
  compensation: '5',
  staffing: '5',
  practiceDev: '5',
  practiceDevBalance: '5',
  philosophy: '5',
  geographicFocus: '5',
  loading: true,
}

function reducer(state, action) {
  switch (action.type) {
    case 'set-year': {
      return { ...state, year: action.payload }
    }
    case 'set-firm-name': {
      return { ...state, firmName: action.payload }
    }
    case 'set-firm-tagline': {
      return { ...state, firmTagline: action.payload }
    }
    case 'set-firm-values': {
      return { ...state, firmValues: action.payload }
    }
    case 'set-expertise': {
      return { ...state, expertise: action.payload }
    }
    case 'set-number-partners': {
      return { ...state, numberOfPartners: action.payload }
    }
    case 'set-infrastructure': {
      return { ...state, infrastructure: action.payload }
    }
    case 'set-employee-dev': {
      return { ...state, employeeDev: action.payload }
    }
    case 'set-compensation': {
      return { ...state, compensation: action.payload }
    }
    case 'set-staffing': {
      return { ...state, staffing: action.payload }
    }
    case 'set-practice-dev': {
      return { ...state, practiceDev: action.payload }
    }
    case 'set-practice-dev-balance': {
      return { ...state, practiceDevBalance: action.payload }
    }
    case 'set-philosophy': {
      return { ...state, philosophy: action.payload }
    }
    case 'set-geographic-focus': {
      return { ...state, geographicFocus: action.payload }
    }
    case 'set-industry': {
      return {
        ...state,
        industries: {
          ...state.industries,
          [action.payload]: !state.industries[action.payload],
        },
      }
    }
    default:
      return state
  }
}

export function AppStateProvider({ children }) {
  const [state, dispatch] = useReducer(
    (state, action) => reducer(state, action),
    initialState
  )

  return (
    <AppStateContext.Provider value={{ state, dispatch }}>
      {children}
    </AppStateContext.Provider>
  )
}

export const useAppState = () => {
  return useContext(AppStateContext)
}
