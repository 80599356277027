import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Skyline from '../../components/Skyline'
import Building from '../../components/Building'
import { ReactComponent as Bilboard } from '../../images/city-assets/leaderboards-bb.svg'
import { ReactComponent as VideoBuilding } from '../../images/city-assets/explore-building-video.svg'

import './style.css'
const ExploreGladrey = (props) => {
  return (
    <Container fluid as="main" className="p-0 main">
      <Skyline />
      <div className="h-road"></div>
      <div className="grass">
        {/* Welcome to Gladrey & bilboard video building */}
        <Row className="g-0 explore-welcome-row">
          <Col sm="12" md="5" className="text-center">
            <Bilboard className="explore-billboard mx-auto img-fluid" />
          </Col>
          <Col sm="12" md="1" className="d-none d-md-flex">
            <div className="v-road"></div>
          </Col>
          <Col sm="12" md="5">
            <VideoBuilding className="explore-billboard mx-auto  img-fluid" />
          </Col>
          <Col sm="12" md="1" className="d-none d-md-flex">
            <div className="v-road"></div>
          </Col>
        </Row>
        {/* Company buildings  */}
        <Row className="g-0">
          <Col sm="12" md="5">
            <Row>
              <Col sm="6">
                <Building name="Company 1" status="1" type="0" />
              </Col>
              <Col sm="6">
                <Building name="Company 2" status="0" type="2" />
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Building name="Company 5" status="1" type="1" />
              </Col>
              <Col sm="6">
                <Building name="Company 6" status="0" type="1" />
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Building name="Company 9" status="1" type="1" />
              </Col>
              <Col sm="6">
                <Building name="Company 10" status="0" type="1" />
              </Col>
            </Row>
          </Col>
          <Col sm="12" md="1" className="d-none d-md-flex">
            <div className="v-road"></div>
          </Col>
          <Col sm="12" md="5">
            <Row>
              <Col sm="6">
                <Building name="Company 3" status="2" type="2" />
              </Col>
              <Col sm="6">
                <Building name="Company 4" status="1" type="0" />
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Building name="Company 7" status="2" type="1" />
              </Col>
              <Col sm="6">
                <Building name="Company 8" status="2" type="0" />
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Building name="Company 11" status="1" type="1" />
              </Col>
              <Col sm="6">
                <Building name="Company 12" status="1" type="0" />
              </Col>
            </Row>
          </Col>

          <Col sm="12" md="1" className="d-none d-md-flex">
            <div className="v-road"></div>
          </Col>
        </Row>
      </div>
      <div className="h-road"></div>
      <div className="h-road"></div>
    </Container>
  )
}
export default ExploreGladrey
