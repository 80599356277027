import { Routes, Route, useLocation } from 'react-router-dom'
import './App.css'

import Home from './pages/Home'
import DesignFirm from './pages/DesignFirm'
import ExploreGladrey from './pages/ExploreGladrey'
import RunFirm from './pages/RunFirm'
import Leaderboards from './pages/Leaderboards'
import ViewTeams from './pages/ViewTeams'
import Help from './pages/Help'
import AwardBids from './pages/AwardBids'
import NotFoundPath from './pages/NotFoundPath'
import BottomLineStats from './pages/BottomLineStats'
import HostAccessCodes from './pages/HostAccessCodes'
import HostDashboard from './pages/HostDashboard'
import Footer from './components/Footer'
import Layout from './components/Layout'
import Login from './pages/Login'

function App() {
  const location = useLocation()
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="design" element={<DesignFirm />} />
          <Route path="run" element={<RunFirm />} />
          <Route path="explore" element={<ExploreGladrey />} />
          <Route path="leaderboards" element={<Leaderboards />} />
          <Route path="teams" element={<ViewTeams />} />
          <Route path="help" element={<Help />} />
          {/** Host */}
          <Route path="host/dashboard" element={<HostDashboard />} />
          <Route path="host/award" element={<AwardBids />} />
          {/** Program Owner */}
          <Route path="/owner/stats" element={<BottomLineStats />} />
          <Route path="/owner/access-codes" element={<HostAccessCodes />} />
          {/** No match route */}
          <Route path="*" element={<NotFoundPath />} />
        </Route>
        {/** Login */}
        <Route path="login" element={<Login />} />
      </Routes>
      {location.pathname !== '/login' && <Footer />}
    </div>
  )
}

export default App
