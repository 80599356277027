import Form from 'react-bootstrap/Form'

// import HelpButton from '../../components/HelpButton'

export default function InputSelect({
  id,
  label,
  helpTitle,
  helpContent,
  options,
  value,
  setValue,
}) {
  return (
    <Form.Select aria-label={label} className="mb-3">
      <option>{label}</option>
      {options.map((opt) => (
        <option key={opt} value={opt}>
          {opt}
        </option>
      ))}
    </Form.Select>
  )
}
